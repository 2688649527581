import React, { useContext, useMemo } from 'react';

import AuthHelper from 'helpers/auth_helper';
import { searchParams } from 'helpers/router_helper';
import { RootContext } from 'contexts/contexts';
import GuestOnboardingHeader from 'components/reusable/header/components/guest_onboarding_header';
import DefaultHeader from 'components/reusable/header/components/default_header/default_header';
import useAdaptiveDesign from 'hooks/use_adaptive_design';

const RootHeader = props => {
  const rootContext = useContext(RootContext);
  const isGuestOnboarding = useMemo(() => rootContext.mode === searchParams.modes.guestOnboarding, [rootContext.mode]);
  const MainHeader = useAdaptiveDesign(DefaultHeader, MobileHeader);

  if (isGuestOnboarding) return <GuestOnboardingHeader />;
  return <MainHeader {...props} />;
};

const MobileHeader = props => {
  if (AuthHelper.logged_in) return null;
  return <DefaultHeader {...props} />;
};

export default RootHeader;
